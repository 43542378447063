import { IModuleCardProps } from "./ModuleCard.interface";
import Placeholder from "./../../shared/assets/imgs/placeholder.svg";
import { Link } from "react-router-dom";
import { routes } from "../../shared/router/routes";
import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import FavoriteButton from "../FavoriteButton/FavoriteButton";
import {
    useGetModuleFavorite,
    useSetModuleFavorite,
    useSetModuleUnfavorite
} from "../../shared/hooks/useModules";
import { IModuleFavoriteResponse } from "../../shared/interfaces/modules.interface";
import { IRequest } from "../../shared/interfaces/authentication.interface";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import { ModuleContext } from "../../shared/providers/module/module.provider";
import { getFromattedPath } from "../../shared/helpers/utils";

function ModuleCard({ title, contentAmount, id }: IModuleCardProps) {
    const { translate } = useContext(TranslationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const { updated, setUpdated } = useContext(ModuleContext);
    const [isFavorite, setIsFavorite] = useState(false);
    const { isLoading, data, refetch } = useGetModuleFavorite(id);
    const setFavorite = useSetModuleFavorite();
    const setUnfavorite = useSetModuleUnfavorite();

    useEffect(() => {
        setIsLoading(isLoading);
        if (isLoading || !data) return;
        const response = data as IRequest<IModuleFavoriteResponse>;
        if (response.success) setIsFavorite(response.data.favourite);
    }, [data, isLoading]);

    useEffect(() => {
        if (isLoading) return;
        refetch();
    }, [updated]);

    const handleFavorite = async () => {
        setIsLoading(true);

        if (!isFavorite) await setFavorite.mutateAsync(id);
        else await setUnfavorite.mutateAsync(id);
        setUpdated(new Date());
        setIsLoading(false);
    };

    return (
        <article className="card card_module d-flex flex-column gap-1">
            <Link to={getFromattedPath(routes.MODULE.path, { moduleId: id })}>
                <figure className="card_thumbnail">
                    <img src={Placeholder} alt={title} />
                </figure>
            </Link>
            <div className="card_info px-1 d-flex flex-column gap-1">
                <Link
                    to={routes.MODULE.path.replace(":id", id)}
                    className="d-flex flex-column gap-1">
                    <h3 className="text-h5-smbd color-neutrals-black">{title}</h3>
                    <div className="my-01 d-flex gap-02 align-items-center">
                        <progress className="card_progress" value="50" max="100"></progress>
                        <span className="text-bd4-md color-neutrals-neutral">50%</span>
                    </div>
                </Link>
                <div className="d-flex justify-content-between gap-1 py-1">
                    {!!contentAmount && (
                        <span className="text-bd2-rg color-neutrals-neutral">
                            {`${contentAmount} ${
                                contentAmount > 1
                                    ? translate("common.units", "units")
                                    : translate("common.unit", "unit")
                            }`}
                        </span>
                    )}
                    <FavoriteButton
                        className="ms-auto"
                        isFavorite={isFavorite}
                        onClick={handleFavorite}
                    />
                </div>
            </div>
        </article>
    );
}

export default ModuleCard;

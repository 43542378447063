import { APP_CONFIGS } from "../constants";
import { formatDate } from "../helpers/utils";
import { IRequest, IRequestError } from "../interfaces/authentication.interface";
import { IDat, IDatRequestPayload, IDatResponse } from "../interfaces/dats.interface";
import { request, strapiRequest } from "./requests.service";

export const getActivityTrackers = async (
    countryIsoCode: string,
    locale: string
): Promise<IRequest<IDat[] | IRequestError>> => {
    try {
        const query = `
            query Dats($filters: DatFiltersInput, $status: PublicationStatus, $locale: I18NLocaleCode) {
                dats(filters: $filters, status: $status, locale: $locale) {
                    id: documentId
                    name
                    label
                    selfTracker
                    startDate
                    endDate
                    frequency
                    options {
                    type: __typename
                    ... on ComponentDatOptionSelectionOption {
                        id
                        options {
                        label
                        value          
                        }
                    }
                    ... on ComponentDatOptionRadioOption {
                        id
                        options {
                        label
                        value
                        }
                    }
                    ... on ComponentDatOptionBooleanOption {
                        id
                        true_label
                        false_label
                    }
                    ... on ComponentDatOptionScaleOption {
                        id
                        min
                        max
                    }
                    }
                }
                }
            `;

        const today = formatDate(new Date(), "YYYY-MM-dd");

        const variables = {
            filters: {
                active: {
                    eq: true
                },
                startDate: {
                    lte: today
                },
                endDate: {
                    gte: today
                },
                countries: {
                    isoCode: {
                        eq: countryIsoCode
                    }
                }
            },
            status: "PUBLISHED",
            locale
        };

        const response = await strapiRequest.post<{ data: { dats: IDat[] } }>(`/graphql`, {
            operationName: "Dats",
            query,
            variables
        });

        return { success: true, data: response.data.data.dats ?? [] };
    } catch (error: any) {
        return { success: false, data: [] as any };
    }
};

export const checkActivityTracker = async (
    payload: IDatRequestPayload
): Promise<IRequest<IDatResponse | IRequestError>> => {
    try {
        const { id, locale, frequency } = payload;
        const response = await request.get<IDatResponse>(
            `/${APP_CONFIGS.api.base}/dat/${id}?locale=${locale}&frequency=${frequency}`
        );
        return { success: true, data: response.data as IDatResponse };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};

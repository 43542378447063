import { useContext, useEffect } from "react";
import { IGlobalLayoutProps } from "./GlobalLayout.interface";
import { AuthenticationContext } from "../../shared/providers/authentication/authentication.provider";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import Loading from "../Loading/Loading";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import { APP_CONFIGS } from "../../shared/constants";
import DatPopup from "../DatComponents/DatPopup/DatPopup";
import { DatContext } from "../../shared/providers/dat/dat.provider";

function GlobalLayout({ children }: IGlobalLayoutProps) {
    const { isAuthenticated } = useContext(AuthenticationContext);
    const { isLoading } = useContext(LoadingContext);
    const { dats } = useContext(DatContext);    

    useEffect(() => {
        document.title = APP_CONFIGS.title;
    }, []);

    return (
        <>
            {isAuthenticated && (
                <div className="layout">
                    {/* {dats.map(d => <DatPopup key={d.id} dat={d}/>)} */}
                    {isLoading && <Loading />}
                    <main className="content">
                        {children}
                    </main>
                </div>
            )}
            {!isAuthenticated && (
                <>
                    {isLoading && <Loading />}
                    {children}
                </>
            )}
        </>
    );
}

export default GlobalLayout;

import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { IModule, IModuleProgressRequest } from "../interfaces/modules.interface";
import {
    checkModuleFavorite,
    getFavoriteModules,
    getFavoriteModulesIds,
    getModule,
    getModules,
    getUnit,
    setModuleFavorite,
    setModuleProgress,
    setModuleUnfavorite
} from "../services/modules.service";

export function useGetModules<T extends any = IModule[]>(
    locale: string,
    options?: UseQueryOptions<any, unknown, T>
) {
    return useQuery({
        queryKey: ["modules", locale],
        queryFn: () => getModules(locale).then((r) => r.data),
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        ...options
    });
}

export function useGetModuleFavorite(payload: string) {
    return useQuery({
        queryKey: ["moduleFavorite", payload],
        queryFn: () => checkModuleFavorite(payload),
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000
    });
}

export function useGetModule<T extends any = IModule[]>(
    id?: string,
    locale?: string,
    options?: UseQueryOptions<any, unknown, T>
) {
    return useQuery({
        queryKey: ["modules", id, locale],
        queryFn: () => {
            if (!id || !locale) return;

            return getModule(id, locale).then((r) => r.data);
        },
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        ...options
    });
}

export function useGetUnit<T extends any = IModule[]>(
    id?: string,
    options?: UseQueryOptions<any, unknown, T>
) {
    return useQuery({
        queryKey: ["unit", id],
        queryFn: () => {
            if (!id) return;

            return getUnit(id).then((r) => r.data);
        },
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        ...options
    });
}

export function useSetModuleProgress() {
    return useMutation((payload: IModuleProgressRequest) => setModuleProgress(payload));
}

export function useSetModuleFavorite() {
    return useMutation((payload: string) => setModuleFavorite(payload));
}

export function useSetModuleUnfavorite() {
    return useMutation((payload: string) => setModuleUnfavorite(payload));
}

export function useGetFavoriteModulesIds() {
    return useMutation(() => getFavoriteModulesIds());
}

export function useGetFavorites() {
    return useMutation((payload: { favoritesIds: string[]; locale: string }) =>
        getFavoriteModules(payload)
    );
}

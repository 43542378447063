import { createBrowserRouter } from "react-router-dom";
import Login, { LoginForm } from "../../pages/login/Login";
import { routes } from "./routes";
import RouteGuard from "../guards/route.guard";
import PasswordSetup from "../../pages/password-setup/PasswordSetup";
import Home from "../../pages/home/Home";
import Translations from "../../pages/translations/Translations";
import Faqs from "../../pages/faqs/Faqs";
import PasswordRecovery from "../../pages/password-recovery/PasswordRecovery";
import Contacts from "../../pages/contacts/Contacts";
import Explorer from "../../pages/explorer/Explorer";
import Module from "../../pages/module/Module";
import ModuleProvider from "../providers/module/module.provider";
import Favorites from "../../pages/favorites/Favorites";
import Unit from "../../pages/unit/Unit";

const router = createBrowserRouter([
    {
        path: routes.LOGIN.path,
        element: <Login />,
        children: [
            {
                path: routes.LOGIN.path,
                element: <LoginForm />
            },
            {
                path: routes.FORGOT_PASSWORD.path,
                element: <PasswordRecovery />
            },
            {
                path: routes.PASSWORD_RECOVERY.path,
                element: <PasswordSetup />
            }
        ]
    },
    {
        path: routes.PASSWORD_RESET.path,
        element: <Login />,
        children: [
            {
                path: routes.PASSWORD_RESET.path,
                element: <PasswordSetup />
            }
        ]
    },
    {
        path: routes.HOME.path,
        element: <RouteGuard children={<Home />} />,
        children: [
            {
                path: routes.HOME.path,
                element: (
                    <ModuleProvider>
                        <Explorer />
                    </ModuleProvider>
                ),
                children: [
                    {
                        path: routes.MODULE.path,
                        element: <Module />,
                        children: [
                            {
                                path: routes.UNIT.path,
                                element: <Unit />
                            }
                        ]
                    },
                    {
                        path: routes.FAVORITES.path,
                        element: <Favorites />
                    }
                ]
            },

            {
                path: routes.FAQS.path,
                element: <Faqs />
            },
            {
                path: routes.CONTACTS.path,
                element: <Contacts />
            },
            {
                path: routes.TRANSLATIONS.path,
                element: <Translations />
            }
        ]
    },
    {
        path: routes.PAGE_404.path,
        element: <p>404</p>
    },
    {
        path: "*",
        element: <p>404</p>
    }
]);

export default router;

import { useCallback, useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import { IUnitProps } from "./Unit.interface";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import { useGetModule, useGetUnit } from "../../shared/hooks/useModules";
import { IModule, IModuleUnit, IUnitItem } from "../../shared/interfaces/modules.interface";
import { routes } from "../../shared/router/routes";
import ComponentPicker from "../../components/UnitComponents/ComponentPicker/ComponentPicker";
import { ModuleContext } from "../../shared/providers/module/module.provider";
import Link from "../../components/Link/Link";
import { DEFAULT_LANGUAGE } from "../../shared/constants";
import UnitCard from "../../components/UnitCard/UnitCard";
import { getFromattedPath } from "../../shared/helpers/utils";

function Unit(props: IUnitProps) {
    const navigate = useNavigate();
    const { lang, translate } = useContext(TranslationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const { module, setModule } = useContext(ModuleContext);

    const [params] = useSearchParams();
    const { unitId: id, moduleId } = useParams();
    const { isLoading, data: unit } = useGetUnit<IModuleUnit>(id);
    const { data: moduleData } = useGetModule<IModule>(moduleId, lang ?? DEFAULT_LANGUAGE);
    const [hasNext, setHasNext] = useState(true);

    const getIndex = useCallback(() => {
        return (module?.units?.findIndex((unit) => unit.id === id) ?? 0) + 1;
    }, [module, id]);

    useEffect(() => {
        setIsLoading(isLoading);
        if (unit || isLoading) return;

        if (isLoading) return;
        if (!unit) navigate(routes.MODULES.path);
    }, [isLoading, module, navigate, setIsLoading]);

    useEffect(() => {
        if (module) return;

        setModule(moduleData);
    }, [module, moduleData]);

    useEffect(() => {
        setHasNext(!!module?.units?.[getIndex()]);
    }, [getIndex, module]);

    return (
        <div className="d-flex flex-column gap-2 bg-neutrals-white h-100">
            <div className="container gx-2 pt-2">
                <div className="row gx-0">
                    <div className="col-12 d-flex flex-column gap-02">
                        {/* TODO add middleware to strapi to return unit index */}
                        <span className="text-bd3-rg color-neutrals-dark">Unit {getIndex()}</span>
                        <h1 className="text-h2-smbd color-neutrals-black mb-02">{unit?.title}</h1>
                        <p className="text-bd2-rg color-neutrals-dark m-0">{unit?.description}</p>
                    </div>
                </div>
            </div>
            {unit?.items.map((item, i) => (
                <ComponentPicker key={i} unitId={id} {...item}></ComponentPicker>
            ))}
            {module && module.units?.[getIndex()] && (
                <div className="container gx-2 bg-blue-50 py-2">
                    <div className="row gx-0">
                        <div className="col-12">
                            <h2 className="text-h4-smbd mb-12">Next module unit</h2>
                            <UnitCard
                                moduleId={module.id}
                                unit={module.units?.[getIndex()]}
                                index={getIndex() + 1}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div
                className={`container gx-2 bg-blue-50 py-2 mt-auto ${hasNext ? "py-2 mt-n2" : "pb-6"}`}>
                <div className="row gx-0">
                    <div className="col-12">
                        <h2 className="text-h4-smbd mb-12">Was this content helpfull?</h2>
                        <div className="d-flex gap-2">
                            <button type="button" className="button_icon color-green-500">
                                <i className="icon icon-24 icon-Thumbs-up--filled" />
                            </button>
                            <button type="button" className="button_icon color-green-500">
                                <i className="icon icon-24 icon-Thumbs-down--filled" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {hasNext && (
                <div className="container bg-neutrals-black px-1 pt-03 mt-n2 pb-53">
                    <div className="row gx-0">
                        <div className="col-12">
                            <Link
                                variant="solid"
                                customCss="w-100"
                                to={getFromattedPath(routes.UNIT.path, {
                                    moduleId: moduleId,
                                    unitId: module?.units?.at(getIndex())?.id
                                })}>
                                Next content
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Unit;

import { useContext, useEffect } from "react";
import ModuleCard from "../../components/ModuleCard/ModuleCard";
import { DEFAULT_LANGUAGE } from "../../shared/constants";
import { useGetModules } from "../../shared/hooks/useModules";
import { IExplorerProps } from "./Explorer.interface";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import { IModule } from "../../shared/interfaces/modules.interface";
import { Outlet, useOutlet } from "react-router-dom";
import { ModuleContext } from "../../shared/providers/module/module.provider";

function Explorer(props: IExplorerProps) {
    const { lang, translate } = useContext(TranslationContext);
    const { updated } = useContext(ModuleContext);
    const { refetch, data: modules } = useGetModules<IModule[]>(lang ?? DEFAULT_LANGUAGE);
    const outlet = useOutlet();

    useEffect(() => {
        refetch();
    }, [updated]);

    return (
        <>
            {outlet && <Outlet />}
            {!outlet && (
                <div className="container gx-2 pt-2 pb-5">
                    <div className="row g-1">
                        <div className="col-12">
                            <h1 className="text-h3-smbd pb-1">
                                {translate("common.explorer", "Explorer")}
                            </h1>
                        </div>
                        <section className="col-12">
                            <h2 className="text-h5-smbd pb-1">All topics</h2>
                            <div className="row g-1">
                                {modules?.map((module) => (
                                    <div className="col-12 col-md-6 col-lg-3" key={module.id}>
                                        <ModuleCard
                                            {...module}
                                            title={module.title}
                                            contentAmount={module.contentAmount}
                                            description={module.description}
                                        />
                                    </div>
                                ))}
                            </div>
                        </section>
                    </div>
                </div>
            )}
        </>
    );
}

export default Explorer;

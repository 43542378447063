import { CSSProperties } from "react";
import { ILinkProps } from "./Link.interface";
import { Link as RouterLink } from "react-router-dom";

function Link({
    label,
    children,
    to,
    variant,
    state = "normal",
    icon,
    isIconRight,
    customCss
}: ILinkProps) {
    const getVariant = () => {
        switch (`${variant}-${state}`) {
            case "solid-normal":
                return {
                    css: "is_solid text-bd2-bd",
                    mainColor: "green-500",
                    textColor: "neutrals-darker"
                };
            case "outlined-normal":
                return {
                    css: "is_outlined text-bd2-bd",
                    mainColor: "green-500",
                    textColor: "neutrals-darker"
                };
            case "link-normal":
                return {
                    css: "is_link text-bd2-bd",
                    mainColor: "green-500",
                    textColor: "CurrentColor"
                };
            case "solid-disabled":
                return {
                    css: "is_solid is_disabled text-bd2-bd",
                    mainColor: "neutrals-lighter",
                    textColor: "neutrals-dark"
                };
            case "outlined-disabled":
                return {
                    css: "is_outlined is_disabled text-bd2-bd",
                    mainColor: "neutrals-lighter",
                    textColor: "neutrals-dark"
                };
            case "link-disabled":
                return {
                    css: "is_link is_disabled text-bd2-bd",
                    mainColor: "",
                    textColor: "CurrentColor"
                };
            default:
                return {
                    css: "",
                    mainColor: "",
                    textColor: ""
                };
        }
    };

    return (
        <RouterLink
            to={to}
            style={
                {
                    "--main-color": `var(--${getVariant().mainColor})`,
                    "--text-color": `var(--${getVariant().textColor})`
                } as CSSProperties
            }
            className={`button ${customCss} ${getVariant().css} ${isIconRight ? "flex-row-reverse" : ""}`}>
            {icon && <i className={`icon icon-24 icon-${icon}`}></i>}
            {label ?? children}
        </RouterLink>
    );
}

export default Link;

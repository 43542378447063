import { useContext, useEffect, useState } from "react";
import {
    toTranslate,
    TranslationContext
} from "../../shared/providers/translation/translation.provider";
import GlobalLayout from "../../components/GlobalLayout/GlobalLayout";
import { LOCALE } from "../../shared/constants";
import { useFormik } from "formik";
import Button from "../../components/Button/Button";
import { setTranslation } from "../../shared/services/translation.service";
import { Link } from "react-router-dom";
import { routes } from "../../shared/router/routes";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";

function Translations() {
    // PAGE ONLY FOR DEVELOPMENT PURPOSES
    const { setIsLoading } = useContext(LoadingContext);
    const { translations } = useContext(TranslationContext);
    const [missingTranslations, setMissingTranslations] = useState();
    const form = useFormik({
        initialValues: {
            json: ""
        },
        onSubmit: async (values) => {
            try {
                setIsLoading(true);
                const translated = JSON.parse(values.json);

                Object.keys(translated).reduce((promises: Promise<any>[], k: string) => {
                    const requests = setTranslation(k, translated[k]);

                    promises = [...promises, requests];

                    return promises;
                }, []);
                form.resetForm();
            } catch (e) {
                console.log(e);
            }
            setIsLoading(false);
        }
    });

    useEffect(() => {
        const missingTranslations = Object.keys(toTranslate).reduce((obj: any, key: any) => {
            obj[key] = { id: toTranslate[key].id };
            Object.keys(LOCALE).forEach((loc: any) => {
                if (!translations[key + "-" + LOCALE[loc]] && LOCALE.en === loc) {
                    obj[key][loc] = toTranslate[key].fallback;
                } else {
                    obj[key][loc] = translations[key + "-" + LOCALE[loc]]
                        ? translations[key + "-" + LOCALE[loc]]
                        : "";
                }
            });
            return obj;
        }, {});

        setMissingTranslations(missingTranslations);
    }, [translations]);

    return (
        <>
            <Link
                to={routes.LOGIN.path}
                style={{ position: "fixed", top: 30, left: 0, zIndex: 1000 }}>
                go back
            </Link>
            <div className="container m-2">
                <div className="row">
                    <div className="col-6">
                        <pre
                            className="p-1 w-100"
                            style={{
                                fontFamily: "monospace",
                                border: "1px solid",
                                textWrap: "wrap",
                                maxHeight: "50vh",
                                overflow: "auto"
                            }}>
                            <p className="mb-1">
                                Translate the each iso code based on the "en" key. Just output the
                                json. Do not translate "id" and do not leave any key empty. Keep the
                                json keys as is please.
                            </p>
                            <ul className="mb-1">
                                <li>Albanian (sq)</li>
                                <li>Hungarian (Hungary) (hu is hu-HU)</li>
                                <li>Portuguese (pt is pt-PT)</li>
                                <li>English (en is en-US)</li>
                                <li>Spanish (es is es-ES)</li>
                                <li>German (de)</li>
                                <li>Turkish (tr)</li>
                                <li>Dutch (nl)</li>
                                <li>Danish (Denmark) (da is da-DK)</li>
                            </ul>

                            {JSON.stringify(missingTranslations, null, "\t")}
                        </pre>
                    </div>
                    <form className="col-6 d-flex flex-column gap-1" onSubmit={form.handleSubmit}>
                        <textarea
                            name="json"
                            onChange={form.handleChange}
                            className="w-100"
                            rows={25}></textarea>
                        <Button type="submit" label="Add to strapi" variant="solid" />
                    </form>
                </div>
            </div>
        </>
    );
}

export default Translations;

import { useContext, useEffect, useState } from "react";
import ModuleCard from "../../components/ModuleCard/ModuleCard";
import { DEFAULT_LANGUAGE } from "../../shared/constants";
import { useGetFavoriteModulesIds, useGetFavorites } from "../../shared/hooks/useModules";
import { IFavoritesProps } from "./Favorites.interface";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import { IModule } from "../../shared/interfaces/modules.interface";
import { ModuleContext } from "../../shared/providers/module/module.provider";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";

function Favorites(props: IFavoritesProps) {
    const { lang, translate } = useContext(TranslationContext);
    const { updated } = useContext(ModuleContext);
    const { setIsLoading } = useContext(LoadingContext);
    const [modules, setModules] = useState<IModule[]>([]);
    const getFavorites = useGetFavorites();
    const getFavoriteIds = useGetFavoriteModulesIds();

    const getFavoriteModules = async () => {
        setIsLoading(true);
        try {
            const response = await getFavoriteIds.mutateAsync();

            if (!response.success) throw new Error();

            const modulesResponse = await getFavorites.mutateAsync({
                locale: lang ?? DEFAULT_LANGUAGE,
                favoritesIds: response.data as string[]
            });

            if (!modulesResponse.success) throw new Error();

            setModules(modulesResponse.data);
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getFavoriteModules();
    }, [updated]);

    return (
        <div className="container gx-2 pt-2 pb-5">
            <div className="row g-1">
                <div className="col-12">
                    <h1 className="text-h3-smbd pb-1">
                        {translate("common.favorites", "Favorites")}
                    </h1>
                </div>
                <section className="col-12">
                    <div className="row g-1">
                        {modules?.map((module) => (
                            <div className="col-12 col-md-6 col-lg-3" key={module.id}>
                                <ModuleCard
                                    {...module}
                                    title={module.title}
                                    contentAmount={module.contentAmount}
                                    description={module.description}
                                />
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Favorites;

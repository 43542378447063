import { useContext, useEffect, useState } from "react";
import { DEFAULT_LANGUAGE } from "../../shared/constants";
import {
    useGetModule,
    useGetModuleFavorite,
    useSetModuleFavorite,
    useSetModuleUnfavorite
} from "../../shared/hooks/useModules";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import { IModule, IModuleFavoriteResponse } from "../../shared/interfaces/modules.interface";
import { IModuleProps } from "./Module.interface";
import { Outlet, Link as RouterLink, useNavigate, useOutlet, useParams } from "react-router-dom";
import { routes } from "../../shared/router/routes";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import { ModuleContext } from "../../shared/providers/module/module.provider";
import FavoriteButton from "../../components/FavoriteButton/FavoriteButton";
import Placeholder from "./../../shared/assets/imgs/placeholder.svg";
import { markdownToHtml } from "../../shared/helpers/utils";
import { IRequest } from "../../shared/interfaces/authentication.interface";
import Link from "../../components/Link/Link";
import UnitCard from "../../components/UnitCard/UnitCard";

function Module(props: IModuleProps) {
    const navigate = useNavigate();
    const outlet = useOutlet();
    const { lang, translate } = useContext(TranslationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const { setModule, setUpdated } = useContext(ModuleContext);
    const { moduleId: id } = useParams();
    const { isLoading, data: module } = useGetModule<IModule>(id, lang ?? DEFAULT_LANGUAGE);
    const [isFavorite, setIsFavorite] = useState(false);

    const { data } = useGetModuleFavorite(id ?? "");
    const setFavorite = useSetModuleFavorite();
    const setUnfavorite = useSetModuleUnfavorite();

    useEffect(() => {
        setIsLoading(isLoading);
        if (isLoading || !data) return;
        const response = data as IRequest<IModuleFavoriteResponse>;
        if (response.success) setIsFavorite(response.data.favourite);
    }, [data, isLoading]);

    const handleFavorite = async () => {
        if (!id) return;
        setIsLoading(true);

        if (!isFavorite) await setFavorite.mutateAsync(id);
        else await setUnfavorite.mutateAsync(id);
        setUpdated(new Date());
        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(isLoading);
        if (isLoading) return;
        if (!module) navigate(routes.MODULES.path);

        setModule(module);
    }, [isLoading, module, navigate, setIsLoading]);

    return (
        <>
            {outlet && <Outlet />}
            {!outlet && (
                <>
                    <div className="h-100 d-flex flex-column pt-2">
                        <div className="container gx-2 mb-2">
                            <section className="module row g-1">
                                <div className="col-12 d-flex flex-column gap-02">
                                    <div className="d-flex justify-content-between">
                                        <span
                                            className={`color-${module?.tag_color ?? "yellow"}-500 text-bd4-bd text-uppercase`}>
                                            {module?.tag}
                                        </span>
                                        <FavoriteButton
                                            className="ms-auto"
                                            isFavorite={isFavorite}
                                            onClick={handleFavorite}
                                        />
                                    </div>
                                    <h1 className="text-h1-bd">{module?.title}</h1>
                                    <div className="d-flex justify-content-between">
                                        <span className="d-flex gap-01 align-items-center color-neutrals-dark text-bd3-rg">
                                            <i className={`icon icon-16 icon-Time`}></i>
                                            {module?.units?.reduce((a, b) => {
                                                return (a += b.items.reduce((c, d) => {
                                                    return (c += d.duration ?? 0);
                                                }, 0));
                                            }, 0)}
                                            min {translate("common.article", "article")}
                                        </span>
                                        {module?.units && (
                                            <span className="color-neutrals-dark text-bd3-rg">
                                                {`2 / ${module?.units?.length} ${
                                                    module?.units?.length > 1
                                                        ? translate("common.units", "units")
                                                        : translate("common.unit", "unit")
                                                }`}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <figure className="col-12">
                                    {/* TODO adicionar parametro no strapi para receber thumbnail do modulo */}
                                    <img src={Placeholder} className="module_thumbnail" />
                                </figure>
                                <div className="col-12 mt-0">
                                    <span
                                        className="module_description color-neutrals-dark text-bd2-rg"
                                        dangerouslySetInnerHTML={{
                                            __html: markdownToHtml(module?.description ?? "")
                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    <ul className="d-flex flex-column gap-02">
                                        {module?.units?.map((unit, i) => (
                                            <li key={i}>
                                                <UnitCard unit={unit} index={i + 1} moduleId={module.id} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </section>
                        </div>
                        <div className="container bg-neutrals-black px-1 pt-03 mt-auto pb-53">
                            <div className="row gx-0">
                                <div className="col-12">
                                    <Link variant="solid" customCss="w-100" to="/">
                                        Continue your journey
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Module;

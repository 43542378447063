import { useMutation } from "react-query";
import { checkActivityTracker, getActivityTrackers } from "../services/dats.service";
import { IDatRequestPayload } from "../interfaces/dats.interface";

export function useGetDats(){
    return useMutation((p: {countryIsoCode: string, locale: string}) => getActivityTrackers(p.countryIsoCode, p.locale))
}
export function useCheckDats(){
    return useMutation((payload: IDatRequestPayload) => checkActivityTracker(payload))
}


import { useCallback, useContext } from "react";
import { markdownToHtml } from "../../../shared/helpers/utils";
import { IRichTextProps } from "./Richtext.interface";
import { ModuleContext } from "../../../shared/providers/module/module.provider";
import { TranslationContext } from "../../../shared/providers/translation/translation.provider";
import { ItemTypes } from "../../../shared/interfaces/modules.interface";
import { useIntersectionObserver } from "../../../shared/hooks/useIntersectionObserver";

function RichText(props: IRichTextProps) {
    const { unitId, id } = props;
    const { progress, setProgress } = useContext(ModuleContext);
    const { lang } = useContext(TranslationContext);

    const checkProgress = useCallback(
        (entry: any) => {
            if (entry.isIntersecting && progress?.progress !== 1) handleProgress();
        },
        [progress]
    );

    const handleProgress = useCallback(() => {
        if (lang && unitId) {
            setProgress({
                unitId: unitId,
                itemId: id,
                progress: 1,
                itemType: ItemTypes.RICH_TEXT,
                itemLocale: lang
            });
        }
    }, []);

    const { ref } = useIntersectionObserver(checkProgress);

    return (
        <div className="container gx-2">
            <div className="row gx-0">
                <div className="col-12">
                    <div
                        className="text-bd2-md rich_text"
                        dangerouslySetInnerHTML={{ __html: markdownToHtml(props.content) }}
                    />
                    <div ref={ref}></div>
                </div>
            </div>
        </div>
    );
}

export default RichText;

import { APP_CONFIGS } from "../constants";
import { IRequest, IRequestError } from "../interfaces/authentication.interface";
import {
    IModule,
    IModuleFavoriteResponse,
    IModuleProgressRequest,
    IModuleProgressResponse,
    IModuleResponse,
    IModulesResponse,
    IModuleUnit,
    IUnitResponse
} from "../interfaces/modules.interface";
import { request, strapiRequest } from "./requests.service";

export const getModules = async (locale: string): Promise<IRequest<IModule[]>> => {
    try {
        const query = `
        query Modules($locale: I18NLocaleCode, $filters: ModuleFiltersInput, $status: PublicationStatus) {
            modules(locale: $locale, filters: $filters, status: $status) {
                id: documentId
                title
                description
                contentAmount
            }
        }`;

        const variables = {
            locale,
            status: "PUBLISHED",
            filters: {
                platforms: {
                    key: {
                        eq: APP_CONFIGS.key
                    }
                }
            }
        };

        const response = await strapiRequest.post<IModulesResponse>(`/graphql`, {
            operationName: "Modules",
            query,
            variables
        });

        return { success: true, data: response.data.data.modules ?? [] };
    } catch (error: any) {
        return { success: false, data: [] };
    }
};

export const getFavoriteModules = async ({
    favoritesIds,
    locale
}: {
    favoritesIds: string[];
    locale: string;
}): Promise<IRequest<IModule[]>> => {
    try {
        const query = `
        query FavoriteModules($locale: I18NLocaleCode, $filters: ModuleFiltersInput, $status: PublicationStatus) {
            modules(locale: $locale, filters: $filters, status: $status) {
                id: documentId
                title
                description
                contentAmount
            }
        }`;

        const variables = {
            locale,
            status: "PUBLISHED",
            filters: {
                platforms: {
                    key: {
                        eq: APP_CONFIGS.key
                    }
                },
                documentId: {
                    in: favoritesIds.length ? favoritesIds : null
                }
            }
        };

        const response = await strapiRequest.post<IModulesResponse>(`/graphql`, {
            operationName: "FavoriteModules",
            query,
            variables
        });

        return { success: true, data: response.data.data.modules ?? [] };
    } catch (error: any) {
        return { success: false, data: [] as any };
    }
};

export const getModule = async (documentId: string, locale: string): Promise<IRequest<IModule>> => {
    try {
        const query = `
            query ModuleQuery($documentId: ID!, $locale: I18NLocaleCode, $status: PublicationStatus) {
                module(documentId: $documentId, locale: $locale, status: $status){
                    title
                    id: documentId
                    description
                    tag
                    tag_color
                    contentAmount
                    units {     
                        id: documentId               
                        title
                        tag
                        tag_color
                        items{
                            type: __typename
                            ... on ComponentItemVideo {
                                id
                                title
                                description
                                url: vimeoUrl
                                duration
                            }
                            ... on ComponentItemTypeForm {
                                id
                                title
                                description
                                formId
                                duration 
                            }
                            ... on ComponentItemRichText {
                                id
                                title
                                content
                                duration
                            }
                            ... on ComponentItemImage {
                                id
                                title
                                description
                                duration
                                image: file{
                                    url
                                    width
                                    height
                                    alt: alternativeText
                                }
                            }
                            ... on ComponentItemFile {
                                id
                                title
                                duration
                            }
                            ... on ComponentItemAudio {
                                id
                                title
                                description
                                duration
                                audio: file{
                                    url
                                    alt: alternativeText
                                }
                            }
                            ... on Error {
                                code
                                message
                            }
                        }
                    }
                }
            }`;

        const variables = {
            documentId,
            locale,
            status: "PUBLISHED"
        };

        const response = await strapiRequest.post<IModuleResponse>(`/graphql`, {
            operationName: "ModuleQuery",
            query,
            variables
        });

        if (!response.data.data.module) throw Error;

        return { success: true, data: response.data.data.module };
    } catch (error: any) {
        return { success: false, data: null as any };
    }
};

export const getUnit = async (documentId: string): Promise<IRequest<IModuleUnit>> => {
    try {
        const query = `
        query UnitQuery($documentId: ID!, $status: PublicationStatus) {
            unit(documentId: $documentId, status: $status) {
                id: documentId
                description
                title
                tag
                tag_color
                items {
                        type: __typename
                        ... on ComponentItemVideo {
                            id
                            title
                            description
                            url: vimeoUrl
                            duration
                        }
                        ... on ComponentItemTypeForm {
                            id
                            title
                            description
                            formId
                            duration
                        }
                        ... on ComponentItemRichText {
                            id
                            title
                            content
                            duration
                        }
                        ... on ComponentItemImage {
                            id
                            title
                            description
                            duration
                            image: file {
                            url
                            width
                            height
                            alt: alternativeText
                            }
                        }
                        ... on ComponentItemFile {
                            id
                            title
                            duration
                        }
                        ... on ComponentItemAudio {
                            id
                            title
                            description
                            duration
                            audio: file {
                            url
                            alt: alternativeText
                            }
                        }
                        ... on Error {
                            code
                            message
                        }
                    }
                }
            }
        `;

        const variables = {
            documentId,
            status: "PUBLISHED"
        };

        const response = await strapiRequest.post<IUnitResponse>(`/graphql`, {
            operationName: "UnitQuery",
            query,
            variables
        });
        
        if (!response.data.data.unit) throw Error;

        return { success: true, data: response.data.data.unit };
    } catch (error: any) {
        return { success: false, data: null as any };
    }
};

export const setModuleProgress = async (
    data: IModuleProgressRequest
): Promise<IRequest<IModuleProgressResponse> | IRequestError> => {
    try {
        const response = await request.post<IModuleProgressResponse>(
            `/${APP_CONFIGS.api.base}/content/module/progress`,
            data
        );

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, data: error };
    }
};

export const setModuleFavorite = async (
    moduleId: string
): Promise<IRequest<any> | IRequestError> => {
    try {
        const response = await request.post<IModuleProgressResponse>(
            `/${APP_CONFIGS.api.base}/content/module/favourite/${moduleId}`
        );

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, data: error };
    }
};

export const setModuleUnfavorite = async (
    moduleId: string
): Promise<IRequest<any> | IRequestError> => {
    try {
        const response = await request.delete(
            `/${APP_CONFIGS.api.base}/content/module/favourite/${moduleId}`
        );

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, data: error };
    }
};

export const checkModuleFavorite = async (
    moduleId: string
): Promise<IRequest<IModuleFavoriteResponse> | IRequestError> => {
    try {
        const response = await request.get<IModuleFavoriteResponse>(
            `/${APP_CONFIGS.api.base}/content/module/favourite/${moduleId}`
        );

        return { success: true, data: response.data as IModuleFavoriteResponse };
    } catch (error: any) {
        return { success: false, data: error };
    }
};

export const getFavoriteModulesIds = async (): Promise<IRequest<string[] | IRequestError>> => {
    try {
        const response = await request.get<IModuleFavoriteResponse[]>(
            `/${APP_CONFIGS.api.base}/content/module/favourite`
        );

        return { success: true, data: response.data.map((d) => d.strapiModuleId) };
    } catch (error: any) {
        return { success: false, data: error };
    }
};

import { useEffect, useState, useRef } from "react";

let observer: IntersectionObserver;
const callbacksMap = new Map();

const getObserver = () => {
    if (!observer) {
        observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const callback = callbacksMap.get(entry.target);
                    if (callback) {
                        callback(entry);
                    }
                });
            },
            {
                root: window.document,
                threshold: 0.9
            }
        );
    }
    return observer;
};

export const useIntersectionObserver = (callback: any) => {
    const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
    const elementRef = useRef<any>(null);

    useEffect(() => {
        const currentElement = elementRef.current;
        const observerInstance = getObserver();

        if (currentElement) {
            callbacksMap.set(currentElement, (entry: any) => {
                setIsIntersecting(entry.isIntersecting);
                if (callback) callback(entry);
            });
            observerInstance.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observerInstance.unobserve(currentElement);
                callbacksMap.delete(currentElement);
            }
        };
    }, [callback]);

    return { ref: elementRef, isIntersecting };
};
